<template>
  <el-main class="rh-medform">
    <h1>Ajouter un pupille</h1>
    <form-tutoratcreate :routes="pupRoutes" :ismedpup="true" />
  </el-main>
</template>
<script>
import FormTutoratcreate from "@/components/rh/formulaires/FormTutoratcreate";

export default {
  components: { "form-tutoratcreate": FormTutoratcreate },
  computed: {
    pupRoutes() {
      return {
        toedit: {
          name: "med_pupilles_edit",
          params: {
            idmed: this.$route.params.idmed,
            idtutorat: this.$store.state.tut.tuteurLastCreatedId,
          },
        },
        tolist: {
          name: "med_pupilles",
          params: {
            idmed: this.$route.params.idmed,
          },
        },
      };
    },
  },
};
</script>
